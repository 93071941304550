







































import Component from 'vue-class-component';
import Vue from 'vue';
import { UserLog } from '@/interfaces/UserLog';
import { User } from '../../interfaces/User';
import { routes } from '@/router/routes';
import firebase from 'firebase/app';
import 'firebase/firestore';

@Component
export default class UserLogView extends Vue {
  private isLoading = true;

  get logs(): UserLog[] {
    return this.$store.getters.userLog;
  }

  get members(): User[] {
    return this.$store.getters.members;
  }

  public mounted() {
    const userLogPromise = this.$store.dispatch('loadUserLog');
    const groupPromise = this.$store.dispatch('loadGroup');
    Promise.all([userLogPromise, groupPromise]).then(() => {
      this.isLoading = false;
    });
  }

  private getRouteIconForModule(moduleName): string[] {
    const translatedModuleName = this.translateModule(moduleName);
    let icon: string[] | null = null;
    routes.forEach((mainRoute) => {
      if (mainRoute.title === translatedModuleName) {
        icon = mainRoute.icon;
      } else {
        mainRoute.children.forEach((childRoute) => {
          if (childRoute.title === translatedModuleName) {
            icon = childRoute.icon;
          }
        });
      }
    });
    if (icon === null) {
      throw new Error('Unknown icon for moduleName: ' + moduleName);
    }
    return icon;
  }

  private navigateToModule(moduleName: string) {
    const translatedModuleName = this.translateModule(moduleName);
    let route: string | null = null;
    routes.forEach((mainRoute) => {
      if (mainRoute.title === translatedModuleName) {
        route = mainRoute.children[0].route;
      } else {
        mainRoute.children.forEach((childRoute) => {
          if (childRoute.title === translatedModuleName) {
            route = childRoute.route;
          }
        });
      }
    });
    if (route === null) {
      throw new Error('Unknown route for moduleName: ' + moduleName);
    }
    this.$router.push(route);
  }

  private translateModule(moduleName) {
    switch (moduleName) {
      case 'Person':
        return 'Datenbank';

      default:
        return moduleName;
    }
  }

  private translateAction(action) {
    switch (action) {
      case 'update':
        return 'bearbeitet';

      case 'create':
        return 'angelegt';

      default:
        throw new Error('Unknown action for userLog: ' + action);
    }
  }

  private translateUser(userId) {
    const user = this.members.find((member) => member.uid === userId);

    return typeof user !== 'undefined' ? user.name : 'Unbekannter User';
  }

  private translateTimestamp(timestamp: string | firebase.firestore.Timestamp) {
    const timeToUse = typeof timestamp === 'string' ? timestamp : timestamp.seconds;
    const date = new Date(timeToUse);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
}
