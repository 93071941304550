



























import Vue from 'vue';
import Component from 'vue-class-component';
import UserLogView from '@/components/userlog/userlogview.vue';

@Component({
  components: {
    UserLogView,
  },
})
export default class Welcome extends Vue {
  constructor() {
    super();
  }

  get currentGroup(): string | null {
    return this.$store.getters.currentGroup;
  }
}
